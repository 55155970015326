<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="policy">
      <div class="d-center">
        <div class="body-policy fadeIn">
          <h1 class="title-md">Rules & Conduct <span>policy</span></h1>
          <div class="mt-5 mb-2">
            <h4>FORMULA FOR PAY-OUT FOR GOLOGER FOR DELIVERY</h4>
            <p>
              The delivery distances for parcels are calculated based on Google
              Maps API. A fare estimate will be provided for the Users and as
              GoLoger, you can check the fare amount for each delivery before
              accepting it.
              <br /><br />
              Ensuring a respectful, safe environment for all GoLogers and Users
              The way you behave while using GoLog can have a big impact on the
              safety and comfort of Users. You are expected to exercise good
              judgment and behave decently towards when riding or driving with
              GoLog — just as you would in any public place. Here are some
              reasons why you could lose access to GoLog as a GoLoger:
              <br /><br />
              What do the Users expect from a GoLoger
            </p>
            <ul>
              <li>
                <p>
                  <b>Receiving parcel immediately after accepting a request.</b>
                  When a GoLoger accepts a request from User, the parcel should
                  be collected by the designated time or by the time stated in
                  the order details page.
                </p>
              </li>
              <li>
                <p>
                  <b>Report damaged parcel to GoLog. </b>
                  If the GoLoger happens to receive a parcel which is damaged
                  upon collection, update in comment session mentioning damaged
                  parcel but User proceeds, or choose to cancel the delivery and
                  list the reason of cancellation to not affect star rating of
                  GoLoger.
                </p>
              </li>
              <li>
                <p>
                  <b>Maintain good delivery standard. </b>
                  Always be on time for pick up and deliver it immediately. Keep
                  a good attitude towards users and fellow Gologers.
                </p>
              </li>
              <li>
                <p>
                  <b>Delivering parcels immediately after receiving it.</b>
                  Any deliveries within state should be received and delivered
                  within 2 hours at maximum, any late deliveries of the GoLoger
                  will be penalized (up to full amount of the delivery), which
                  the GoLoger will be opt out of our future programs or rated
                  lower by User.
                </p>
              </li>
              <li>
                <p>
                  <b>Handle item with care. </b>
                  On the midst of delivery, GoLoger should ensure the condition
                  of the parcel which may require GoLoger to stop and check the
                  parcel time to time. If the items are found to be damaged and
                  not declared by the User or GoLoger at the beginning, it will
                  be the GoLoger’s sole responsibility on the damage of items.
                </p>
              </li>
              <li>
                <p>
                  <b>Not canceling a delivery after accepting it.</b>
                  Any cancellation done after accepting a job will be penalized
                  up to a full amount of the delivery fee. However, any
                  cancellation 2 hours prior the pick up time will not be
                  penalized. Cancellation of jobs can be done by informing admin
                  through WhatsApp (0172881753) and mention the order ID.
                </p>
              </li>
              <li>
                <p>
                  <b>Damaging User’s or GoLoger’s property. </b>
                  For example, damaging the car of GoLoger when passing parcels,
                  or damaging, open or keeping the parcel that should be
                  delivered.
                </p>
              </li>
              <li>
                <p>
                  <b>Use of inappropriate and abusive language or gestures.</b>
                  For example, asking overly personal questions, using verbal
                  threats, and making comments or gestures that is aggressive,
                  sexual, discriminatory, or disrespectful.
                </p>
              </li>
              <li>
                <p>
                  <b
                    >Unwanted contact with the GoLoger or fellow passenger after
                    the delivery is over.</b
                  >
                  For example, texting, calling, or visiting someone in person
                  after a delivery has been completed.
                </p>
              </li>
              <li>
                <p>
                  <b>Breaking the local law while using GoLog. </b>
                  For example, deliver open containers of alcohol or drugs into
                  the car; break local traffic laws such as speed limits; or
                  using GoLog to commit a crime, including drug and human
                  trafficking.
                </p>
              </li>
            </ul>
            <p>
              Should we found out of your behaviour as demonstrate above, we may
              contact you so we can launch our investigation. Depending on the
              nature of the concern, we may put a hold on your account during
              our investigation and such decision shall rest be at the final
              hand of ours. During the investigation or the period of your
              account being suspended, you agree that there shall be no damages
              or losses claimable against us.
              <br /><br />
              Should the issues raised are serious or a repeat offense, or you
              refuse to cooperate, you may lose access to GoLog permanently.
              <br /><br />
              Any behavior involving violence, sexual misconduct, harassment,
              discrimination, or illegal activity while using GoLog can result
              in the immediate loss of access to your account permanently.
              <br /><br />
              Additionally, when law enforcement is involved, we will cooperate
              with their investigation.
              <br /><br />
              A GoLoger is our partner in GoLog and is responsible for
              delivering parcels in a timely and effective manner. However,
              there is never in any circumstances that employment relationship
              or agent and principal relationship being formed.
            </p>
          </div>
          <div class="my-2">
            <h4>TERM OF USES</h4>
            <p>
              A “Cancellation Fee” which is of RM5 of the agreed contractual fee
              will be applied in the following circumstances: As a GoLoger, you
              agree to our Terms of Use when you sign up for your account. We
              may take action against you for violating these terms, including
              permanently closing your account. For example the failure to
              maintain accurate, complete, and up-to-date account information,
              including having an invalid or expired payment method on file;
              allowing a person who does not meet the minimum age requirement to
              use your account while unaccompanied, or if you don’t meet that
              age requirement yourself.
              <br /><br />
              You would agree that the cancellation fee from time to time be
              increase with or without notice to you.
            </p>
          </div>
          <div class="my-2">
            <h4>FRAUD or ILLEGITIMATE BEHAVIOUR</h4>
            <p>
              Fraudulent or illegitimate behavior undermines the trust on which
              GoLog is built. We may deactivate any account(s) associated with
              this type of activity, including: abusing promotions; collusion
              between rider and GoLoger; disputing fares for fraudulent or
              illegitimate reasons; or duplicate accounts.
            </p>
          </div>

          <div class="my-2">
            <h4>CANCELLATION RATE</h4>
            <p>
              A driver cancellation is when you accept a trip request and then
              cancel the trip. Cancellations create a poor rider experience and
              negatively affect other drivers. We understand that there may be
              times when something comes up and you have to cancel an accepted
              trip. But minimizing cancellations is critical for the reliability
              of the system. <br /><br />
              How is my cancellation rate calculated? Your cancellation allowed
              will be a maximum of 3 times with valid reason. Any cancellation
              without reason and no show will be imposed a full penalty amount
              and might lead to a permanent ban from the Golog system. Appeal of
              ban can be done through support@glogc.com and we shall determine
              with discretionary power as agreed by you to consider the appeal
              from you and any decision shall be considered as final and that we
              may or may not give our reason.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0);
  },
};
</script>
